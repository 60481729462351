import { h } from "preact";
import { Reaction } from "../Reaction";
import { useWidgetApp } from "./useWidgetApp";
import styles from "./App.module.scss";

export const App = () => {
  const { widget } = useWidgetApp();
  return (
    <div class={styles["app-wrapper"]}>
      {widget?.type === "reaction" && <Reaction widget={widget} />}
    </div>
  );
};

import config from "../config";

export type ApiRequestOptions = RequestInit & {
  external?: boolean;
};

type RequestResponse<T> = {
  response?: T;
  error?: any;
}

export const request = async <T>(path: string, options: ApiRequestOptions= {}): Promise<RequestResponse<T>> => {
  try {
    const url = options.external ? path: `${config.BASE_URL}${path}`;
    const res = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...(options?.headers || {})
      },
      method: options.method || "GET",
      ...(options || {})
    });

    const response = await res.json();

    return {
      response,
    };
  } catch (e) {
    return {
      error: e,
    };
  }
};

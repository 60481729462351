import {request} from "../../helpers/request"
import { useEffect, useState } from 'preact/hooks';
import {Widget} from "../../models/Widget";

const getWidgetId = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  return params.get('id');
};

export const useWidgetApp = () => {
  const [widget, setWidget] = useState<Widget>();
  const fetchWidget = async () => {
    const widgetId = getWidgetId();
    if (!widgetId) return
    const { response } = await request<Widget>(`/widgets/${widgetId}`);
    if (response) {
      setWidget(response);
    }
  }

  useEffect(() => {
    fetchWidget();
  }, []);

  return {
    widget
  }
}

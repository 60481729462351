import { h } from "preact";
import { Widget } from "../../models/Widget";
import styles from "./Reaction.module.scss";

interface ReactionProps {
  widget: Widget | undefined;
}

export const Reaction = ({ widget }: ReactionProps) => {
  return (
    <div class={styles.wrapper}>
      <h1>{widget?.title}</h1>
      {widget?.description && <p>{widget?.description}</p>}
      <div class={styles["reactions-wrapper"]}>
        {widget?.options?.map((option) => (
          <div key={option.id} class={styles.reaction}>
            {option.name}
          </div>
        ))}
      </div>
    </div>
  );
};
